<template>
  <div>
    <div id="table" class="brands">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item>加工单管理</el-breadcrumb-item>
        <el-breadcrumb-item>加工单售后</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="screen">
        <el-form ref="form" label-width="100px" label-position="left">
          <div class="flex flex-wrap screen_item">
            <el-form-item
              label="售后/加工单号:"
              style="width: calc(25% - 56px); margin-left: 20px"
            >
              <el-input
                v-model="filter.filter"
                placeholder="售后/加工单号"
                style="width: 100%"
                @keyup.enter.native="handleSearch"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="发货单号:"
              style="width: calc(25% - 56px); margin-left: 20px"
            >
              <el-input
                v-model="filter.zsh"
                placeholder="供应商发货单号"
                style="width: 100%"
                @keyup.enter.native="handleSearch"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="加工渠道:"
              style="width: calc(25% - 56px); margin-left: 20px"
            >
              <el-select
                v-model="filter.channel"
                placeholder="请选择加工渠道"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in machining.channels"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="审核状态:"
              style="width: calc(25% - 56px); margin-left: 20px"
            >
              <el-select
                v-model="filter.state"
                placeholder="请选择审核状态"
                clearable
                style="width: 100%"
              >
                <el-option label="未审核" value="0" />
                <el-option label="已审核" value="1" />
              </el-select>
            </el-form-item>
            <el-form-item
              label="类型:"
              style="width: calc(25% - 56px); margin-left: 20px"
            >
              <el-select
                v-model="filter.machiningType"
                placeholder="请选择"
                clearable
                style="width: 100%"
              >
                <el-option label="售后" value="SERVICE" />
                <el-option label="废弃" value="ABNORMAL" />
              </el-select>
            </el-form-item>
            <el-form-item
              label="是否寄回:"
              style="width: calc(25% - 56px); margin-left: 20px"
            >
              <el-select
                v-model="filter.back"
                placeholder="是否寄回供应商"
                clearable
                style="width: 100%"
              >
                <el-option label="是" value="1" />
                <el-option label="否" value="0" />
              </el-select>
            </el-form-item>
            <el-form-item
              label="转交库房:"
              style="width: calc(25% - 56px); margin-left: 20px"
            >
              <el-select
                v-model="filter.huser"
                placeholder="是否转交库房"
                clearable
                style="width: 100%"
              >
                <el-option label="是" value="1" />
                <el-option label="否" value="0" />
              </el-select>
            </el-form-item>
            <el-form-item
              label="转交总库房:"
              style="width: calc(25% - 56px); margin-left: 20px"
            >
              <el-select
                v-model="filter.zhuser"
                placeholder="是否转交总库房"
                clearable
                style="width: 100%"
              >
                <el-option label="是" value="1" />
                <el-option label="否" value="0" />
              </el-select>
            </el-form-item>
            <el-form-item
              label="售后分类:"
              style="width: calc(25% - 56px); margin-left: 20px"
            >
              <el-cascader
                :show-all-levels="false"
                v-model="filter.type"
                :props="customProps"
                :options="lists"
                @change="handleChange"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item
              label="添加时间:"
              style="width: 43%; margin-left: 20px"
            >
              <el-date-picker
                v-model="filter.start"
                type="date"
                placeholder="开始时间"
                value-format="yyyy-MM-dd"
                style="width: calc(45% - 28px)"
              />
              <p style="display: inline-block; width: 98px; text-align: center">
                <span
                  style="
                    display: inline-block;
                    width: 40px;
                    height: 2px;
                    background: #e6e6e6;
                    transform: translateY(-3px);
                  "
                ></span>
              </p>
              <el-date-picker
                v-model="filter.end"
                type="date"
                placeholder="结束时间"
                value-format="yyyy-MM-dd"
                style="width: calc(45% - 28px)"
              />
            </el-form-item>
           
            <el-form-item
              label="商品品牌:"
              style="width: calc(25% - 56px); margin-left: 16px"
            >
              <el-select
                v-model="filter.brands"
                placeholder="请选择商品品牌"
                clearable
                multiple
              >
                <el-option
                  v-for="(item, index) in brands"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="所属店铺:"
              style="width: calc(25% - 56px); margin-left: 20px"
            >
              <el-select
                v-model="filter.store"
                placeholder="请选择订单所属店铺"
                style="width: 100%"
                multiple
                clearable
              >
                <el-option
                  v-show="is_vcp == true"
                  label="大明豪京东VCP"
                  :value="0"
                />
                <el-option
                  v-for="(item, index) in stores"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label-width="0px"
              style="
                width: 100%;
                text-align: center;
                margin: 0;
                padding-bottom: 15px;
              "
            >
              <el-button
                type="primary"
                class="queryBtn"
                style="width: 100px"
                :loading="loading"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button
                plain
                class="resetBtn"
                style="width: 100px; margin-left: 20px"
                @click="handleResetSearch"
                >重置</el-button
              >
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 查询 -->
      <div class="table">
        <!-- 数据 -->
        <Items
          :list.sync="list"
          @handleLoad="handleLoad"
          @handleExport="handleExport"
        />
        <!-- 分页 -->
        <div class="paging flex flex-justify-e">
          <Paginator
            :total.sync="total"
            @handelCurrentChange="handelCurrentChange"
          />
        </div>
      </div>
      <iframe :src="iframe" style="display: none"></iframe>
    </div>
  </div>
</template>
<script>
import Items from "./Items";
import { servicesIndex, servicesExports, servicesTags,search } from "@/api";
import { mapState } from "vuex";
export default {
  name: "views.services.Index",
  components: { Items },
  inject: ["handelLoading", "handelMessageBoxConfirm"],
  data() {
    return {
      iframe: null,
      loading: false,
      filter: {
        filter: "",
        channel: null,
      },
      brands:[],
      stores:[],
      total: { total: 0, size: 10, current: 1 },
      list: [],
      lists: [],
      is_vcp:true,
      customProps: {
        // multiple: false, // 启用多选
        emitPath: false, // 只返回该节点的值
        value: "id", // 自定义当前数组的键名 - value
        label: "title", // 自定义当前数组的键名 - label
        children: "child", // 自定义当前数组的键名 - children
        expandTrigger: "click", // 次级菜单的展开方式 - click/hover
        // lazy: "true", // 是否动态加载子节点，需与 lazyLoad 方法结合使用
        // lazyLoad: "true", // 加载动态数据的方法，仅在 lazy 为 true 时有效
      },
    };
  },
  computed: {
    ...mapState("storage", ["machining"]),
  },
  methods: {
    handleChange(e) {
      console.log(e);
    },
    handleLoad() {
      this.handelLoading(true);
      servicesIndex(this.filter)
        .then((response) => {
          this.handelLoading((this.loading = false));
          this.list = response.data;
          this.total = this.$util.paginator(response.meta);
        })
        .catch(() => {
          this.handelLoading((this.loading = false));
        });
      servicesTags(this.filter)
        .then((response) => {
          console.log(response, "12312213");
          this.handelLoading((this.loading = false));
          this.lists = response.data;
        })
        .catch(() => {
          this.handelLoading((this.loading = false));
        });
    },
    handleLoadBrands(){
                search({brands:1,order_stores:1, users:1}).then((response)=>{
                    console.log(response);
                    this.brands = response.response.options.brands
                    this.stores = response.response.options.order_stores
                    // this.users = response.response.options.users
                    this.is_vcp = response.response.options.is_vcp
                })
            },
    handelCurrentChange(current) {
      this.filter.page = current;
      this.handleLoad();
    },
    handleResetSearch() {
      this.filter = {};
      this.handleSearch();
    },
    handleSearch() {
      this.loading = true;
      this.filter.page = 1;
      console.log('21312321');
      this.handleLoad();
    },
    handleExport() {
      this.handelMessageBoxConfirm(
        "确定导出当前检索的数据吗？",
        (instance, done) => {
          instance.confirmButtonLoading = true;
          servicesExports(this.filter)
            .then((response) => {
              instance.confirmButtonLoading = false;
              if (!response.state) {
                this.$notify.error({
                  title: "提示",
                  message: response.message,
                });
                return;
              }
              done();

              this.iframe = response.response.url;
            })
            .catch(() => {
              instance.confirmButtonLoading = false;
            });
        }
      );
    },
  },
  created() {
    this.handleLoad();
    this.handleLoadBrands()
  },
};
</script>
<style lang="scss" scoped>
#table {
  .screen {
    padding: 18px 10px 0 18px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin-bottom: 18px;
  }
  .table {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
  }
}
</style>

<style lang="scss">
.el-scrollbar__wrap {
  overflow: hidden;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}
@import "@/assets/scss/table.scss";
#table.brands {
  .table .el-table th.el-table__cell:nth-child(2) {
    text-align: center;
    &::after {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 35px;
      background: #cccccc;
      content: "";
    }
  }
  .table .el-table .el-table__body-wrapper td:nth-child(2) {
    text-align: center;
    border-left: 1px solid #e6e6e6;
    box-sizing: border-box;
  }
}
</style>
