<template>
    <div>
        <div class="orderBtns">
            <el-button type="primary" @click="handleExport">导出</el-button>
        </div>        
        <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%">
            <el-table-column prop="service_sn" label="售后单号" width="92"/>  
            <el-table-column prop="external_sn" label="加工单号" width="95"/>
            <el-table-column prop="channel_format" label="加工渠道" width="105"/>
            <el-table-column label="供货商">
                <template slot-scope="scope">
                  {{scope.row.servicesku[0].skus.supplier.title}}
                </template>
            </el-table-column>
            <el-table-column  label="厂商发货单号" width="118">
                <template slot-scope="scope">
                    {{scope.row.machining.external_zsh}}
                </template>
            </el-table-column>  
                  
            <el-table-column label="寄回厂商" width="88">
                <template slot-scope="scope">
                    {{scope.row.is_send_back ? '是' : '否'}}
                </template>
            </el-table-column>
            <el-table-column label="转交库房" width="88">
                <template slot-scope="scope">
                    {{scope.row.is_send_huser? '是' : '否'}}
                </template>
            </el-table-column>
            <el-table-column label="转交总库房" width="100">
                <template slot-scope="scope">
                    {{scope.row.is_z_send_huser ? '是' : '否'}}
                </template>
            </el-table-column>
            <el-table-column prop="postscript_format" label="售后分类及备注" width="135" >
                <template slot-scope="scope">
                    {{scope.row.postscript_format}}<br>
                    责任人:{{scope.row.persons.username}}<br>
                    类型:{{scope.row.machining_type_format}}
                </template>
            </el-table-column>
            <!-- <el-table-column prop="machining_type_format" label="类型" width="60"/>             -->
            <el-table-column prop="state_format" label="审核状态" width="85"/>
            <el-table-column prop="created_at_format" label="添加时间" width="135">   
                <template slot-scope="scope">
                    {{scope.row.created_at_format}}<br>
                    创建人:{{scope.row.user.username}}
                </template>
            </el-table-column>          

            <el-table-column label="操作" width="158">
                <template slot-scope="scope">
                    <div>
                     <router-link @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)"  class="Print" style="color: #3d7fe9;" :to="{name:'services.show',  params:{id:scope.row.id}}">查看</router-link>
                     <span  @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)"  class="Print" @click="Print(scope.row.machining_id,scope.row.id)">打印</span>
                    </div>
                    <span @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)"  class="Print" v-if="!scope.row.is_send_huser && !scope.row.is_z_send_huser"  @click="handelStatess(scope.row)">转交总库房</span>
                    <span @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)"  class="Print" v-if="!scope.row.is_send_huser && !scope.row.is_z_send_huser"  @click="handelStates(scope.row)">转交库房</span>
                    <span @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)"  class="Print" v-if="!scope.row.is_send_back" @click="handelSendBack(scope.row)">寄回</span>
                    <span @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)"  class="Print" v-if="scope.row.machining_type == 'SERVICE' && scope.row.state == 0" @click="handelState(scope.row)">审核</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import { servicesBack,servicesState ,gethuser,getzhuser} from '@/api'
    export default {
        name: 'views.services.Items',
        data() {
            return {
                      active:''
            }
        },
        inject: ['handelMessageBoxConfirm'],
        props:{
            list:{type:Array,required: true, default:()=>{return []}},
        },
        methods: {
            handelSendBack(item){
                this.handelMessageBoxConfirm('确定寄回供应商吗？', (instance, done) => {
                    instance.confirmButtonLoading = true;
                    servicesBack(item.id).then(response => {
                        instance.confirmButtonLoading = false;
                        if (!response.state) {
                            this.$notify.error({title: '提示',message: response.message})
                            return
                        }
                        done()
                        this.$emit('handleLoad')
                        this.$notify.success({title: '提示',message: response.message})
                    }).catch(()=>{
                        instance.confirmButtonLoading = false;
                    })
                })
            },
            Print(e,a){
             console.log(e,a);
                 this.$router.push({ name:'services.Afterrinting', params:{e : e,a:a} })
            },
            //点击预览鼠标悬停事件
            mouseOver($event) { 
             $event.currentTarget.className = 'Print active'
            },
            mouseLeave($event) {  
             $event.currentTarget.className = 'Print'
            },
            handelState(item){
                this.handelMessageBoxConfirm('确定审核吗？', (instance, done) => {
                    instance.confirmButtonLoading = true;
                    servicesState(item.id).then(response => {
                        instance.confirmButtonLoading = false;
                        if (!response.state) {
                            this.$notify.error({title: '提示',message: response.message})
                            return
                        }
                        done()
                        this.$emit('handleLoad')
                        this.$notify.success({title: '提示',message: response.message})
                    }).catch(()=>{
                        instance.confirmButtonLoading = false;
                    })
                })
            }, 
            handelStatess(item){
                console.log(item);
                this.handelMessageBoxConfirm('确定已转交总库房吗?', (instance, done) => {
                    instance.confirmButtonLoading = true;
                    getzhuser(item.id).then(response => {
                        instance.confirmButtonLoading = false;
                        if (!response.state) {
                            this.$notify.error({title: '提示',message: response.message})
                            return
                        }
                        done()
                        this.$emit('handleLoad')
                        this.$notify.success({title: '提示',message: response.message})
                    }).catch(()=>{
                        instance.confirmButtonLoading = false;
                    })
                })
            },
            handelStates(item){
                console.log(item);
                this.handelMessageBoxConfirm('确定已转交库房吗?', (instance, done) => {
                    instance.confirmButtonLoading = true;
                    gethuser(item.id).then(response => {
                        instance.confirmButtonLoading = false;
                        if (!response.state) {
                            this.$notify.error({title: '提示',message: response.message})
                            return
                        }
                        done()
                        this.$emit('handleLoad')
                        this.$notify.success({title: '提示',message: response.message})
                    }).catch(()=>{
                        instance.confirmButtonLoading = false;
                    })
                })
            }, 
            handleExport(){
                this.$emit('handleExport')
            },            
        },
        created() {

        }
    }
</script>
<style lang="scss" scoped>
.Print{
margin-left: 5px;
cursor:pointer;
 color: #3d7fe9;
}
.active{
    color: red;
}
</style>